<template>

  <b-card body-class="p-0" header-class="border-0 disbursements">
    <template v-slot:header>
      <b-row align-v="center" class="filter-inputs">
        <b-col sm="12" lg="2">
          <h3 class="mb-0">Total ({{ transactionsTotal }})</h3>
        </b-col>

        <b-col sm="12" lg="7" class="text-right col-center filter-selects">
          <b-row>
            <b-col sm="12" lg="4" class="data-list">
              <!-- Search input --> 
              <b-form-input 
                v-model="filter" 
                placeholder="Filter payment batches" 
                class="filter-input" 
                v-bind:class="{ active: isActive, focused: isFocused }"
                @keyup="selectedDistributor"
                @focus="focused"
              >
              </b-form-input>
             </b-col>
            <b-col sm="12" lg="4">
                        <el-date-picker v-model="formattedFromDate"
                          type="datetime" @change="filterDates($event)"
                          placeholder="from" class="input-center">
                        </el-date-picker>
            </b-col>
            <b-col sm="12" lg="4">
                <el-date-picker v-model="formattedToDate"
                  type="datetime" @change="filterDates($event)"
                  placeholder="until">
                </el-date-picker>
            </b-col>
          </b-row>
          </b-col>
        <b-col sm="12" lg="3" class="text-right  report-buttons">
          <a href="#!" class="btn btn-sm btn-primary" @click="reload()">Reload</a>
        </b-col>
      </b-row>
    </template>
    <spinner v-if="isLoading"></spinner>
    <el-table 
        class="table-responsive table disbursements"
        :data="tableData"
        header-row-class-name="thead-light"
        empty-text="No Payment Batches found"
        v-if="!isLoading"
        @sort-change="sortChange" 
    >
      <!-- <el-table-column align="center" type="selection"
                       :selectable="canSelectRow"
                       width="80px">
      </el-table-column> -->
      <el-table-column label="Batch Number"
                       prop="ourRef" sortable :min-width="120">
      </el-table-column>
      <el-table-column label="Distributor"
                       prop="supplierName" sortable :min-width="115">
      </el-table-column>
      <el-table-column label="Start Time"
                       :formatter="dateFormatter"
                       prop="batchStartTime" sortable :min-width="110">
      </el-table-column>
      <el-table-column label="End Time"
                       :formatter="dateFormatter"
                       prop="batchEndTime" sortable :min-width="100">
      </el-table-column>
      <el-table-column label="Count"
                       prop="totalPayments" sortable :min-width="95">
      </el-table-column>
      <el-table-column label="Amount"
                       :formatter="currencyFormatter"
                       prop="totalAmount" sortable :min-width="100"> 
      </el-table-column>

      <el-table-column label="" min-width="90px" class="th-button">
        <template v-slot="{row}">
          <button class="btn btn-sm btn-primary"
                  :data-id="row.Id"
                   @click="getReport(row.supplierId, row.batchStartTime, row.batchEndTime)">
            
              <span class="button-text">Transactions</span>
              <span class="button-icon"><i class="fas fa-chart-pie"></i></span>
          </button>
        </template>
      </el-table-column>
    </el-table>
      <el-pagination :current-page="currentPage"
                    @current-change="handleCurrentChange"
                    :total="rows"
                    :page-size="pageSize"
                    align="right"
                    v-if="!isLoading"
                   >
    </el-pagination>
  </b-card>
</template>
<script>
  import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, DatePicker, TimePicker, Pagination, Select, Option } from 'element-ui'
  import config from '../../config'
  import { helpers } from '../../helpers'
  import Spinner from '../../components/Spinner';
  import { DateTime } from 'luxon';

  export default {
    name: 'batches-table',
    components: {
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
      [DatePicker.name]: DatePicker,
      [TimePicker.name]: TimePicker,
      [Pagination.name]: Pagination,
      [Select.name]: Select,
      [Option.name]: Option,
      Spinner
    },
    methods: {
      sortChange($event){
        // Get sort change data
        const { prop, order } = $event;
        // Set sort order
        this.setSortOrder(prop, order);
        // Check for sort order and table data accordingly
        if(order == "ascending") {
          this.tableData = this.tableData.sort((a, b) => (a[prop] > b[prop]) ? 1 : -1);
        } 

        if(order == "descending") {
          this.tableData = this.tableData.sort((a, b) => (a[prop] < b[prop]) ? 1 : -1);
        }
        console.log('Table data:', this.tableData);
      },
      setSortOrder(prop, order) {
        this.sort = prop;
        this.sortOrder == order == 'ascending' ? 'asc' : 'desc';
      },
      handleCurrentChange(val) {
        this.currentPage = val;
      },
      currencyFormatter(row, column, cellValue) {
        return this.formatCurrency(cellValue);
      },
      txnFormatter(row, column, cellValue) {
        return helpers.padNumber(cellValue);
      },
      dateFormatter(row, column, cellValue) {
        return this.formatDate(cellValue);
      },
      formatDate(cellValue) {
        var date = new Date(cellValue);
        return ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear() + " " + ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2);
      },
      
      formatDateISO(inputDate) {
      var date = new Date(inputDate);
      return (
        date.getFullYear() +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + date.getDate()).slice(-2) +
        " " +
        ("0" + date.getHours()).slice(-2) +
        ":" +
        ("0" + date.getMinutes()).slice(-2)
      );
    },

    getReport(suppId, startDate, endDate) {
      //this.isLoading = true;
      var http = this.axios.create({
        baseURL: config.apiUrl,
        timeout: 60000,
        headers: { Authorization: this.$store.getters["accounts/token"] }
      });

      const report = {
        fromDate: this.formatDateISO(startDate),
        toDate: this.formatDateISO(endDate),
        filter: "",
        sort: "",
        sortOrder: this.sortDirection,
        supplierId: suppId,
        userId: '0'
      };

      console.log("Report:", report);

      http
        .post("/transaction/pdf", report, {
          responseType: "blob"
        })
        .then(response => {
          console.log(response);
          var newBlob = new Blob([response.data], {
            type: "application/octet-stream"
          });
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(
              newBlob,
              "transactions-report.pdf"
            );
            return;
          }
          const data = window.URL.createObjectURL(newBlob);
          console.log(data);
          var link = document.createElement("a");
          link.style = "display: none;";
          link.href = data;
          link.download = "transaction-report.pdf";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.isLoading = false;
          setTimeout(function() {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
            this.isLoading = false;
          }, 100);
        })
        .catch(err => {
          this.isLoading = false;
        });
    },
      formatCurrency(cellValue) {
        return parseFloat(cellValue).toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' });
      },
      percentageFormatter(row, column, cellValue) {
        return this.formatPercentage(cellValue);
      },
      formatPercentage(row, column, cellValue) {
        return parseFloat(cellValue).toFixed(2) + ' %';
      },
      changeSupplier(selectedValue) {
        this.supplierId = selectedValue
        var http = this.axios.create({
          baseURL: config.apiUrl,
          timeout: 30000,
          headers: { 'Authorization': this.$store.getters['accounts/token'] }
        });
        http.post('/batches/all', {
              fromDate: this.fromDate,
              toDate: this.toDate,
              filter: this.filter,
              sort: this.sort,
              sortOrder: this.sortDirection,
              supplierId: this.$store.getters["accounts/user"].supplierId
          }).then(response => {
          if (response.data.length > 0) {
            this.tableData = response.data;
            this.handleCurrentChange(1);
          } else {
            this.tableData = [];
            this.handleCurrentChange(1);
          }
        }).catch((err) => {
          this.tableData = [];
          this.handleCurrentChange(1);
          this.isLoading = false;
        });
      },
      filterDates(event) {
        this.isLoading = true;

        // Get supplier id 
        const supplierIds = this.$store.getters["accounts/user"].supplierIds;

        var http = this.axios.create({
          baseURL: config.apiUrl,
          timeout: 30000,
          headers: { 'Authorization': this.$store.getters['accounts/token'] }
        });

        console.log('Getting batches....')

        http.post('/batches/all', {
            supplierId: supplierIds,
            fromDate: this.fromDate,
            toDate: this.toDate,
            sort: this.sort,
            sortOrder: this.sortDirection
        }).then(response => {
          if (response.data.length > 0) {
            /* Table data */
            this.tableData = response.data;
            this.masterData = response.data;

            console.log('Table data (loaded):', this.tableData);
            // Call method that will filter table data
            this.displayFilteredTableData();
            this.handleCurrentChange(1);
            this.isLoading = false;
          } else {
            this.tableData = [];
            this.handleCurrentChange(1);
            this.isLoading = false;
          }
          
        }).catch((err) => {
          this.tableData = [];
          this.masterData = []
          this.handleCurrentChange(1);
          this.isLoading = false;
        });

      },
      displayFilteredTableData() {
        this.tableData = this.masterData.filter(s => s.supplierName.includes(this.filter))
      },
      onFilterHandler() {
        // 1. Get fromDate (this.fromDate) & toDate (this.toDate)

        // 2. Format date to match server requirements

        // 3. Do async call here

        // 4. Populate table with response data
      },
      reload() {
        const query = Object.assign({}, this.$route.query);
      for(var obj in query){
        delete query[obj];
      }
      this.$router.replace({ query });
      this.$router.go();
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      viewTransactions(row) {
        this.$router.push('/transactions?fromDate=' + row.batchStartTime + '&toDate=' + row.batchEndTime + '&filter=&supplierId=' + row.supplierId + '&sort='+ this.sort +'&sortOrder=' + this.sortDirection)
      },
      canSelectRow(row, index) {
        return row.completed == 'True';
      },
      filterTableBySearch($event) {
        // Get typed in value in input
        const query = $event.target.value.trimStart().toLowerCase();
        // Get pressed key code   
        const pressKeyCode = $event.keyCode;
        // Store length of query 
        const queryLength = query != "" ? query.length : 0;

        if(queryLength > 0) {
          // Show cancel icon
          this.showDataList = true;
          // When the backspace key is pressed
          if(pressKeyCode == 8) {
            // Get value in input once again
            const value = $event.target.value.trimStart().toLowerCase();
            console.log(value)
            // Filter data 
            this.selectedDistributor(value.toLowerCase());
          }
          // Filter clients data
          this.selectedDistributor(query.toLowerCase())
        } else {
          //  // Show cancel icon
          // this.showDataList = false;
          // Reset table data when there is no value in input
          this.resetTableData()
        }
      },
      filterTableData(query = "") {
          // Filter clients by search query
          const filterdClients = this.dataList.filter(
            c =>
            c.ourRef.toLowerCase().startsWith(query.toLowerCase()) ||
            c.status.toLowerCase().includes(query.toLowerCase()) ||
            c.supplierName.toLowerCase().includes(query.toLowerCase()) ||
            c.theirRef.toLowerCase().includes(query.toLowerCase()) ||
            c.totalAmount.toLowerCase().includes(query.toLowerCase()) 
            );
          // Store the filtered data as the new tatble data values 
          this.dataList = filterdClients;
          console.log(this.dataList)
      },
      resetTableData() {
        // Store clients from store and update table data
        this.formatTransactionsData(this.tableData);
      },
      formatTransactionsData(transactions) {
        // Convert data from observable to normal array
        const formattedTransactions = JSON.parse(JSON.stringify(transactions));
        // Key for getting unique values
        const key = "clientName";
        // Create a set so that data only has unique values before we push it into dataList
        const uniqueTransactions = [...new Map(formattedTransactions.map(item => [item[key], item])).values()];
        // Store the unique transactions in the dataList array
        this.dataList = JSON.parse(JSON.stringify(uniqueTransactions));
      },
      cancelFilterBySearch() {
        // Get the search input and set it to null/empty
        const input = this.$refs.filterinput.$el.value = "";
        // Remove focused class
        this.isFocused = false;
        // Remove active class
        this.isActive = false;
        // Reset table data
        this.resetTableData();
      },
      removeCancelSearchIcon($event) {
        // Get typed in value in input
        const queryLength = $event.target.value.trimStart().length; 
        // Remove cancel icons based on length value length
        if(queryLength == 0) {
          // Reverse is active
          this.isActive = false;
          // Reverse is focused
          this.isFocused = false
        }
      },
      formatClientName(clientName) {
        return clientName.slice(0, 4).trimEnd() + "...";
      },  
      removeReportPeriod(id) {
        // Convert string to JSON
        const formattedClients = JSON.parse(JSON.stringify(this.clients));
         // Get index of selected time
         const filteredArray = formattedClients.filter(p => p.id !== id);
         // Removed index array
         this.clients = filteredArray;
         // Remove id from report suppliers
         this.reportSupplierIds = this.reportSupplierIds.filter(i => i != id);
      },
      blur($event) {
        console.log($event);
        // Remove focused class on search
        this.isFocused = false;
        // Close data list dive
        this.showDataList = false;
      },
      focused() {
        // Set focused class on search input wrapper
        this.isFocused = true; 
        // Open data list div
        this.showDataList = true;
      },    
      selectedDistributor(supplierNames) {
        // keep data list showing
        this.showDataList = true;
        // Empty text string
        this.filter = supplierNames.target.value.trimStart();
        // Store query value
        const query = supplierNames.target.value.trimStart();
        // Close the data list
        this.showDataList = false;
        // Remove focus class
        this.isFocused = false;
        // Filtered array
        const filteredArray = [];
        // Split entered values
        const suppliers = supplierNames.target.value.split(',');
        // Loop through names to filter table data
        for(var i=0; i< suppliers.length; i++){
          // filter term formatting
          const filter = suppliers[i].toLowerCase().trimStart();
          // Fitler table data here
          this.tableData = this.masterData.filter(
            p => 
            p.ourRef.toLowerCase().includes(filter) ||
            p.ourRef.toLowerCase().startsWith(filter) ||
            p.theirRef.toLowerCase().includes(filter) ||
            p.theirRef.toLowerCase().startsWith(filter) ||
            p.supplierName.toLowerCase().includes(filter) ||
            p.supplierName.toLowerCase().startsWith(filter)
          ).concat(filteredArray)
        }

        console.log('Filter:', this.tableData);
      },
      formatFromDate(date){
          console.log('date:', date);
          return `${date.getUTCFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${("0" + date.getDate()).slice(-2)} 00:00`;
      }
      ,
      formatToDate(date){
        console.log('date:', date);
          return `${date.getUTCFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${("0" + date.getDate()).slice(-2)} 23:59`;
      }
      // sortChange(sortData){
      //   this.sort = sortData.prop;
      //   this.sortOrder == sortDate.order == 'ascending' ? 'asc' : 'desc';
      // }
    },
    data() {
      return {
        filter: '',
        sort: '',
        sortDirection: '',
        supplierIDS: [],
        isActive: false,
        isFocused: false,
        showDataList: false,
        dataList: [],
        clients: [],
        disabled: true,
        reportSupplierIds: [],
        currentPage: 1,
        pageSize: 10,
        supplierId: '',
        fromDate: DateTime.now().startOf('day').toJSDate(),
        toDate: DateTime.now().endOf('day').toJSDate(),
        isLoading: false,
        suppliers: [
          {
            Name: '',
            Id: 0
          }
        ],
        tableData: [
          {
            DateCreated: '00-00-0001 00:00',
            Amount: 0,
            Name: 'Loading... ',
            clientName: 'Loading...',
            Type: 'Purchase',
          }
        ],
        masterData: [],
        fields: [
          {
            key: 'Amount', label: 'value(R$)', sortable: true,
            formatter: (value, key, item) => value.toLocaleString('en-ZA', { style: 'currency', currency: 'R' })
          },
        ]
      }
    },
    computed: {
      formattedFromDate: {
        get: function () {
          return this.formatDateISO(this.fromDate);
        },
        set: function (newValue) {
          this.fromDate = newValue;
        }
      },
      formattedToDate: {
        get: function () {
          return this.formatDateISO(this.toDate);
        },
        set: function (newValue) {
          this.toDate = newValue;
        }
      },
      rows() {
        return this.tableData.length;
      },
      displayData() {
        if (!this.tableData || this.tableData.length === 0) return [];
        //TODO: Handle Filtering Here
        //TODO: Check why Pagination is not working... 
        return this.tableData.slice(this.pageSize * this.currentPage - this.pageSize, this.pageSize * this.currentPage);
      },
      transactionsTotal() {
        var total = 0;
        for (var i = 0; i < this.tableData.length; i++) {
          total += parseFloat(this.tableData[i].totalAmount)
        }
        return this.formatCurrency(total);
      },
      isSupplier() {
        return this.$store.getters['accounts/supplierId'] != '' ? true : false;
      },
      loggedinSupplierId() {
        return this.$store.getters['accounts/supplierId'] == '' ? '' : this.$store.getters['accounts/supplierId'];
      }
    },
    mounted() {
      this.isLoading = true;
      var http = this.axios.create({
        baseURL: config.apiUrl,
        timeout: 30000,
        headers: { 'Authorization': this.$store.getters['accounts/token'] }
      });
      if (this.isSupplier) {
        console.log(this.isSupplier)
        //get only the current supplier and only load their own transactions
      

        http.get('/supplier/')
          .then(response => {
            this.suppliers = [response.data];
            this.supplierId = response.data.Id;
            http.post('/batches/all', {
              fromDate: this.formattedFromDate,
              toDate: this.formattedToDate,
              sort: this.sort,
              sortOrder: this.sortDirection,
            }).then(response => {
              /* Table data */
              this.tableData = response.data;
              this.masterData = response.data;
              // Call method that will filter table data
              //this.selectedDistributor(this.filter);
              consol
              this.handleCurrentChange(1);
              this.isLoading = false;
            }).catch((err) => {
               /* Table data */
              this.tableData = [];
              this.masterData = [];
              this.handleCurrentChange(1);
              this.isLoading = false;
            });
          });
        
      } else {
        http.get('/supplier')
          .then(response => {
            this.suppliers = response.data;
            this.suppliers.splice(0, 0, { Id: '', Name: 'Show all' });
          });
        http.post('/batches/all', {
              fromDate: this.formattedFromDate,
              toDate: this.formattedToDate,
              filter: this.filter,
              sort: this.sort,
              sortOrder: this.sortDirection
        }).then(response => {
          this.tableData = response.data;
          this.masterData = response.data;
          this.handleCurrentChange(1);
          this.isLoading = false;
        }).catch((err) => {
          this.tableData = [];
          this.masterData = [];
          this.handleCurrentChange(1);
          this.isLoading = false;
        });
      }
    }
  }
</script>
<style scoped>
.col-center {
  display: flex;
  justify-content: space-between;
}

.input-center{
  margin: 0 10px;
}

thead tr th .el-checkbox__input {
  display: none !important;
}

.card .table td, .card .table th {
    padding-left: 0; 
    padding-right: 0;
}

.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 100% !important;
    margin: 0 !important;
} 

.disbursements {

}



</style>
